<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-form @submit.prevent="handleSubmit">
      <v-card :disabled="loading">
        <v-card-title>
          Formulário Tipos Equipamentos
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.name"
            label="Nome"
            autofocus
            outlined
          ></v-text-field>
          <v-combobox
            v-model="form.aliases"
            label="Tags"
            small-chips
            multiple
            outlined
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn type="submit" color="primary" dark :loading="loading">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { store, update } from "@/services/product_types.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";

export default {
  mixins: [ToastsMixin],

  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        name: "",
      },
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.resetForm();
    },

    openForUpdate(data) {
      this.form = {
        ...data,
      };
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    resetForm() {
      this.form = {
        name: "",
      };
    },

    async handleCreate() {
      this.loading = true;

      try {
        const response = await store(this.form);

        this.$emit("submitted", response);
        this.close();
        this.toast("Tipo registado com sucesso");
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400) {
          this.toastError(error.response.data.message);
          return;
        }
        this.toastError("Não foi possível registar");
      } finally {
        this.loading = false;
      }
    },

    async handleUpdate() {
      this.loading = true;

      try {
        const response = await update(this.form.id, this.form);

        this.$emit("submitted", response);
        this.close();
        this.toast("Tipo atualizado com sucesso");
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400) {
          this.toastError(error.response.data.message);
          return;
        }
        this.toastError("Não foi possível registar");
      } finally {
        this.loading = false;
      }
    },

    handleSubmit() {
      if (this.form.id) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
  },
};
</script>
