<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-form @submit.prevent="handleSubmit">
      <v-card :disabled="loading">
        <v-card-title>
          Mesclar categorias
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Transfere os equipamentos para a categoria principal e exclui as
          categorias mescladas, exceto a principal.
        </v-card-subtitle>

        <v-card-text>
          <v-radio-group
            v-model="form.target"
            label="Escolha a categoria a ser mantida"
          >
            <v-radio
              v-for="origin in form.origins"
              :key="origin.id"
              :label="origin.name"
              :value="origin.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text>Cancelar</v-btn>
          <v-btn type="submit" color="primary" :loading="loading"
            >Mesclar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { merge } from "@/services/product_types.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";

export default {
  mixins: [ToastsMixin],

  data() {
    return {
      dialog: false,
      form: {
        origins: [],
        target: null,
      },
      loading: false,
    };
  },

  methods: {
    open(origins) {
      this.dialog = true;
      this.form = {
        origins: [...origins],
        target: null,
      };
    },

    close() {
      this.dialog = false;
    },

    async handleSubmit() {
      this.loading = true;

      try {
        const response = await merge({
          ...this.form,
          origins: this.form.origins.map((item) => item.id),
        });
        this.$emit("merged", response);
        this.close();
        this.toast("Categorias mescladas com sucesso");
      } catch (error) {
        if (error.response?.status === 400) {
          this.toastError(error.response.data.message);
          return;
        }

        this.toastError(
          "Ocorreu uma falha ao mesclar as categorias, entre em contato com o suporte"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
