<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card :disabled="confirmLoading || cancelLoading">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text v-html="options.description"> </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel()" text :loading="cancelLoading">
          {{ options.cancelText }}
        </v-btn>
        <v-btn
          @click="handleConfirm()"
          :color="options.color"
          dark
          :loading="confirmLoading"
        >
          {{ options.confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      description: "",
      cancelText: "",
      confirmText: "",
      onCancel: null,
      onConfirm: null,
      confirmLoading: false,
      cancelLoading: false,
      color: "primary",
      options: {},
    };
  },

  methods: {
    confirm(title, onConfirm, onCancel = null, options = null) {
      this.dialog = true;
      this.title = title;
      this.onConfirm = onConfirm;
      this.onCancel = onCancel;
      this.mergeOptions(options);
    },

    mergeOptions(options) {
      if (!options) {
        this.options = this.getDefaultOptions();
        return;
      }

      this.options = {
        ...this.getDefaultOptions(),
        ...options,
      };
    },

    getDefaultOptions() {
      return {
        dark: true,
        description: "",
        color: "primary",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
      };
    },

    close() {
      this.dialog = false;
    },

    setLoading(loading) {
      this.loading = loading;
    },

    handleConfirm() {
      this.onConfirm({
        close: this.close,
        startLoading: this.startConfirmLoading,
        stopLoading: this.stopLoading,
      });
    },

    handleCancel() {
      if (!this.onCancel) {
        this.close();
        return;
      }

      this.handleCancel({
        close: this.close,
        startLoading: this.startCancelLoading,
        stopLoading: this.stopLoading,
      });
    },

    stopLoading() {
      this.confirmLoading = false;
      this.cancelLoading = false;
    },

    startConfirmLoading() {
      this.confirmLoading = true;
    },

    startCancelLoading() {
      this.cancelLoading = true;
    },
  },
};
</script>
