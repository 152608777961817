<template>
  <v-navigation-drawer v-model="drawer" width="500" clipped app right>
    <template #prepend>
      <v-card-title>
        {{ category.name || "Categoria" }}
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </template>
    <v-list>
      <v-subheader> PEQUIPAMENTOS </v-subheader>

      <v-skeleton-loader
        v-if="loading"
        max-width="400"
        type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-list-item v-for="product in products" :key="product.id">
        <v-list-item-icon>
          <v-icon>mdi-cellphone-link</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="product.name"> </v-list-item-title>
          <v-list-item-subtitle>
            <router-link
              :to="{
                name: 'ProductsList',
                params: { lead_number: product.lead },
              }"
              target="_blank"
              v-text="product.lead"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { indexProducts } from "@/services/product_types";

export default {
  data() {
    return {
      drawer: false,
      category: null,
      products: [],
      loading: false,
    };
  },

  methods: {
    open(category) {
      this.drawer = true;
      this.category = category;
      this.loadProducts(category.id);
    },

    close() {
      this.drawer = false;
    },

    async loadProducts(id) {
      this.products = [];
      this.loading = true;
      this.products = await indexProducts(id);
      this.loading = false;
    },
  },
};
</script>
