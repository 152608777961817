<template>
  <ConfigPage>
    <ConfigItem>
      <v-text-field
        v-model="search"
        @change="loadItems()"
        solo
        label="Pesquisar"
        single-line
        prepend-inner-icon="mdi-magnify"
        hide-details
        class="mb-2"
        clearable
      >
        <template #append-outer>
          <v-btn @click="loadItems()" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <v-toolbar v-if="selected.length" flat>
        <v-toolbar-title>
          {{ selected.length }} itens carregados
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <ScopeProvider scope="configs.products.edit">
          <v-btn @click="selected = []" small text>
            <v-icon left>mdi-close</v-icon> Cancelar
          </v-btn>
          <v-btn @click="handleClickDelete()" color="red" dark small text>
            <v-icon left>mdi-delete</v-icon> Excluir</v-btn
          >
          <v-btn
            @click="handleClickMerge()"
            class="ml-2"
            color="primary"
            small
            text
          >
            <v-icon left>mdi-set-merge</v-icon> Mesclar
          </v-btn>
        </ScopeProvider>
      </v-toolbar>
      <v-toolbar v-else flat>
        <v-toolbar-title v-text="__('Tipos de equipamento', 'settings')" />
        <v-spacer></v-spacer>

        <ScopeProvider scope="configs.products.edit">
          <v-btn @click="handleClickAdd()" color="primary"> Registar </v-btn>
        </ScopeProvider>
      </v-toolbar>
      <v-sheet class="px-4">
        <v-chip-group column>
          <v-chip v-for="item in selected" :key="item.id" small>
            {{ item.name }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
      <v-data-table
        v-if="options"
        color="primary"
        v-model="selected"
        show-select
        :items="items"
        :headers="headers"
        :server-items-length="totalItems"
        :options.sync="options"
        must-sort
      >
        <template #item.name="{ item }">
          <ScopeProvider scope="configs.products.edit">
            <a @click.prevent="handleUpdateItem(item)">
              {{ item.name }}
            </a>

            <template #not>
              {{ item.name }}
            </template>
          </ScopeProvider>
        </template>

        <template #item.products_count="{ item }">
          {{ item.products_count }}
          <v-btn @click="handleClickSeeProducts(item)" icon class="ml-1">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </ConfigItem>

    <ConfirmationDialog ref="confirmDialog" />
    <EquipamentTypesFormDialog ref="formDialog" @submitted="loadItems()" />
    <MergeEquipamentTypesDialog
      ref="mergeDialog"
      @merged="loadItemsAndUnselectAll()"
    />
    <ProductsDrawer ref="productsDrawer" />
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import i18n from "@/mixins/i18n";
import data_table from "@/tools/data_table";
import { index, destroy } from "@/services/product_types";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ConfirmationDialog from "@/components/tools/ConfirmationDialog.vue";
import MergeEquipamentTypesDialog from "@/components/equipamentTypes/MergeEquipamentTypesDialog.vue";
import EquipamentTypesFormDialog from "@/components/equipamentTypes/EquipamentTypesFormDialog.vue";
import ProductsDrawer from "@/components/equipamentTypes/ProductsDrawer.vue";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  mixins: [i18n, ToastsMixin],
  components: {
    ConfigPage,
    ConfigItem,
    ConfirmationDialog,
    EquipamentTypesFormDialog,
    MergeEquipamentTypesDialog,
    ProductsDrawer,
    ScopeProvider,
  },

  data() {
    return {
      items: [],
      selected: [],
      options: null,
      totalItems: 0,
      search: "",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Equipamentos",
          value: "products_count",
          sortable: false,
        },
      ],
      loading: false,
    };
  },

  methods: {
    handleClickSeeProducts(type) {
      this.$refs.productsDrawer.open(type);
    },

    handleClickAdd() {
      this.$refs.formDialog.open();
    },

    handleUpdateItem(item) {
      this.$refs.formDialog.openForUpdate(item);
    },

    handleClickMerge() {
      this.$refs.mergeDialog.open(this.selected);
    },

    loadItemsAndUnselectAll() {
      this.selected = [];
      this.loadItems();
    },

    async loadItems(options = null) {
      if (!options) {
        options = this.options;
      }

      this.loading = true;
      this.items = [];
      try {
        const response = await index(
          data_table.getParams(options, this.search)
        );

        this.items = response.data;
        this.totalItems = response.meta.total;
      } catch (error) {
        this.toastError(
          "Não foi possível carregar tipos de equipamentos, tente novamente mais tarde"
        );
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    handleClickDelete() {
      this.$refs.confirmDialog.confirm(
        "Excluir os itens carregados?",
        async ({ close, startLoading, stopLoading }) => {
          startLoading();
          try {
            await this.destroySelected();
            close();
            this.toast("Itens removidos");
            this.selected = [];
            this.loadItems();
          } catch (error) {
            if (error.response?.status === 400) {
              this.toastError(error.response.data.message);
              return;
            }
            this.toastError("Não foi possível excluir as categorias");
          } finally {
            stopLoading();
          }
        },
        null,
        {
          color: "red",
          confirmText: "Excluir",
          description: `<ul>
                <li>Apaga todas as categorias selecionadas</li>
                <li>Não é possível reverter</li>
                <li>Interrompe caso haja algum equipamento. Nesse caso faça uma mesclagem</li>
            </ul>`,
        }
      );
    },

    destroySelected() {
      return destroy({
        ids: this.selected.map((item) => item.id),
      });
    },
  },

  watch: {
    options(value) {
      this.loadItems(value);
    },
  },

  computed: {
    selectedText() {
      return this.selected.map((item) => item.name).join(", ");
    },
  },

  created() {
    this.options = data_table.getOptions();
  },
};
</script>
